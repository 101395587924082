import React from 'react';
import { Box, Typography, Grid, CardContent, CardMedia, Container, Button } from '@mui/material';
import './styles/style.css';
import mainImage from '../assets/images/about4.jpg';
import businessImage from '../assets/images/business_international.jpg'; // Add your business image here
import Header from '../components/header';

export default function LandingPage() {

  const handleVisitWebsiteClick = () => {
    window.open('https://business.earthlinkgroup.com', '_blank'); // Open the website in a new tab
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Header />
      
      {/* Hero Section */}
      <Box
        sx={{
          height: '60vh',
          backgroundImage: `url(${mainImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        <Typography variant="h2" component="div">
          Welcome to EarthLink Business
        </Typography>
      </Box>

      {/* Study Cards Section */}
      <Container sx={{ py: 6 }}>
        <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
          Explore Our Business Services
        </Typography>
        
        {/* New Business International Section */}
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              height="300"
              image={businessImage} // Use the image for helping people start businesses internationally
              alt="Helping people start business internationally"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Helping You Start Your Business Internationally
              </Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>
                EarthLink Business is dedicated to supporting entrepreneurs in starting their businesses internationally. 
                Our programs and resources provide the guidance you need to navigate global markets, legal requirements, and business strategies. 
                Join us and grow your business across borders with confidence.
              </Typography>
              <Button variant="contained" color="primary" onClick={handleVisitWebsiteClick}>
                Visit Website
              </Button>
            </CardContent>
          </Grid>
        </Grid>
      </Container>

      {/* Picture with Description */}
      <Box
        sx={{
          backgroundImage: `url(${mainImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '40vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          p: 4,
        }}
      >
        <Typography variant="h4" component="div">
          Join our programs and build a brighter future.
        </Typography>
      </Box>

      {/* Big Footer */}
      <Box sx={{ backgroundColor: '#333', color: '#fff', py: 6 }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                About Us
              </Typography>
              <Typography variant="body2">
                EarthLink Education is committed to providing top-notch educational services and opportunities worldwide.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Contact Us
              </Typography>
              <Typography variant="body2">
                Email: info@earthlinkeducation.com
                <br />
                Phone: +250 788 447 599
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Follow Us
              </Typography>
              <Typography variant="body2">
                Facebook | Twitter | Instagram | LinkedIn
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
