import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import logo from '../assets/images/logo.png';
import ApplyNowButton from '../brands/ApplyButton';

const drawerWidth = 240;

const navItems = [
  { name: 'Home', link: '/' },
  {
    name: 'Study Abroad',
    link: '/study-abroad',
    // subItems: [
    //   { name: 'Overseas Education', link: '/study-abroad' },
    //   { name: 'International internship', link: '/international-internship' },
    //   { name: 'Short Course', link: '/short-course' },
    //   { name: 'USA', link: '/study-in-abroad/usa' },
    //   { name: 'Canada', link: '/study-in-abroad/canada' },
    //   { name: 'UK', link: '/study-in-abroad/uk' },
    // ],
  },
  {
    name: 'Business Abroad',
    link: '/business-abroad',
    // subItems: [
    //   { name: 'Book a Flight', link: '/air-ticket' },
    //   { name: 'Flight Schedule', link: '/air-ticket/schedule' },
    // ],
  },
  {
    name: 'Other Services',
    link: '',
    subItems: [
      { name: 'Air Ticket', link: '/air-ticket' },
      { name: 'Visa', link: '/visa' },
      { name: 'International Camps', link: '/summer-camp' },


    ],
  },
  { name: 'About', link: '/about' },
  { name: 'Contact', link: '/contact' },
];

const authItems = [
  { name: 'Sign In', link: '/login' },
];

const useStyles = makeStyles({
  navBarScrolled: {
    backgroundColor: '#1976d2',
  },
  activeMenu: {
    backgroundColor: '#1976d2',
    color: '#fff',
  },
  menuButton: {
    textTransform: 'capitalize',
    fontSize: 20,
    px: 2,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#03adfc',
    },
  },
  dropdownMenu: {
    color: '#fff',
    Width: 450,
    backgroundColor: '#1976d2',
  },
});

function Header(props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const classes = useStyles();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleMenuClick = (event, menuName) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(menuName);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h4" sx={{ my: 4.5, fontSize: 20, textAlign: 'center' }}>
        <img src={logo} alt="logo" style={{ width: 70, height: 50 }} />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              sx={{
                textAlign: 'center',
                backgroundColor: location.pathname === item.link ? '#b0bec5' : '#1976d2',
                color: location.pathname === item.link ? '#000' : '#fff',
              }}
              component={Link}
              to={item.link}
            >
              <ListItemText primary={item.name.toLowerCase()} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {authItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              sx={{
                textAlign: 'center',
                backgroundColor: location.pathname === item.link ? '#b0bec5' : '#1976d2',
                color: location.pathname === item.link ? '#000' : '#fff',
              }}
              component={Link}
              to={item.link}
            >
              <ListItemText primary={item.name.toLowerCase()} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = props.window !== undefined ? () => props.window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" className={classes.navBarScrolled}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h4" component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>
            <img src={logo} alt="logo" style={{ width: 120, height: 80 }} />
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', gap: 2 }}>
              {navItems.map((item) => (
                item.subItems ? (
                  <Box key={item.name}>
                    <Button
                      aria-controls={openMenu === item.name ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu === item.name ? 'true' : undefined}
                      onClick={(e) => handleMenuClick(e, item.name)}
                      className={classes.menuButton}
                      sx={{
                        backgroundColor: location.pathname === item.link ? '#b0bec5' : '#1976d2',
                        color: location.pathname === item.link ? '#000' : '#fff',
                      }}
                    >
                      {item.name.toLowerCase()}
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu === item.name}
                      onClose={handleMenuClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      classes={{ paper: classes.dropdownMenu }}
                    >
                      {item.subItems.map((subItem) => (
                        <MenuItem
                          key={subItem.name}
                          onClick={handleMenuClose}
                          component={Link}
                          to={subItem.link}
                          sx={{ color: '#1976d2' }}
                        >
                          {subItem.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                ) : (
                  <Button
                    key={item.name}
                    component={Link}
                    to={item.link}
                    className={classes.menuButton}
                    sx={{
                      backgroundColor: location.pathname === item.link ? '#b0bec5' : 'transparent',
                      color: location.pathname === item.link ? '#000' : '#fff',
                    }}
                  >
                    {item.name.toLowerCase()}
                  </Button>
                )
              ))}
            </Box>
            <Divider orientation="vertical" flexItem sx={{ mx: 1, display: { xs: 'none', sm: 'block' } }} />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {authItems.map((item) => (
                <Button
                  key={item.name}
                  component={Link}
                  to={item.link}
                  className={classes.menuButton}
                  sx={{
                    backgroundColor: location.pathname === item.link ? '#b0bec5' : 'transparent',
                    color: location.pathname === item.link ? '#000' : '#fff',
                  }}
                >
                  {item.name.toLowerCase()}
                </Button>
              ))}
              <ApplyNowButton />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#1976d2', color: '#fff' },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  );
}

Header.propTypes = {
  window: PropTypes.func,
};

export default Header;
